* {
  /* 去除默认边距 */
  margin: 0;
  padding: 0;
  /* 元素计算边框大小 */
  box-sizing: border-box;
}
/* img {
  width: 100%;
  display: block;
} */
/* body { */
/* 全局字体大小 */
/* 控制全局样式(rem)大小 */
/* font-size: 16px; */
/* } */
